import DEFAULT_META_TAGS from '@/constants/meta'
import useBootstrapData from './useBootstrapData'

export default function useHotelPageTitle(
  defaultTitle: (typeof DEFAULT_META_TAGS.title)[keyof typeof DEFAULT_META_TAGS.title]
) {
  const { referral, prePopData } = useBootstrapData()
  const cityName = prePopData?.hotels?.endLocation?.cityName
  const isSEMCampaign = referral?.clickId?.includes('hotel') && cityName
  const pageTitle = isSEMCampaign
    ? (`The Best Deals on ${cityName} Hotels at Priceline.com` as const)
    : defaultTitle
  return pageTitle
}
