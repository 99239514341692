import React, { SyntheticEvent } from 'react'
import { Box, Text, Flex, ChatMessage, Image } from 'pcln-design-system'
import styled from 'styled-components'
import { TravelBotLogoImage } from '@pcln/uc-chat-bot'
import CityLink, { getClickHandler } from './CityLink'

const TextP = Text.p

const ImageWrapper = styled(Box)`
  border-radius: 60px;
  min-width: 64px;
  cursor: pointer;
`

const getCityListComponent = (setDiscoveryCity: (city: string) => void) => {
  return function CitiesList(props: {
    cities: string[]
    postfixStr?: string
    prefixString?: string
  }) {
    const {
      cities,
      postfixStr = 'Look at the locations above and select one to start your search!',
      prefixString
    } = props

    return (
      <ChatMessage
        mb={4}
        Icon={TravelBotLogoImage}
        variation="initial"
        message={
          <Box borderRadius={16}>
            {prefixString &&
              prefixString.split('\n').map((str, index) => {
                return (
                  <Text key={`prefixStr-${index.toString()}`} fontSize={2}>
                    {str}
                  </Text>
                )
              })}
            {cities?.map((location, index) => {
              const [city, state, ...rest] = location.split(',')

              const lastString = rest[rest.length - 1]

              const imgUrl =
                lastString.includes('jpeg') || lastString.includes('png')
                  ? lastString
                  : undefined

              if (imgUrl) {
                rest.pop()
              }

              return (
                <Box key={`citiesList-${index.toString()}`} my={3}>
                  <Flex>
                    <ImageWrapper>
                      <Image
                        src={imgUrl}
                        alt={`image of ${city}`}
                        width="64px"
                        height="64px"
                        borderRadius={16}
                        objectFit="cover"
                        onClick={(e: SyntheticEvent) => {
                          e.preventDefault()
                          void getClickHandler({
                            city,
                            state,
                            setDiscoveryCity
                          })
                        }}
                      />
                    </ImageWrapper>
                    <Box ml={3}>
                      <Box>
                        <CityLink
                          city={city}
                          state={state}
                          setDiscoveryCity={setDiscoveryCity}
                        />
                      </Box>
                      <TextP fontSize={0} lineHeight="12px" mt="0px">
                        {rest?.join(', ')}.
                      </TextP>
                    </Box>
                  </Flex>
                </Box>
              )
            })}
            {postfixStr?.split('\n').map((str, index) => {
              return (
                <Text key={`string-${index.toString()}`} fontSize={2}>
                  {str}
                </Text>
              )
            })}
          </Box>
        }
      />
    )
  }
}

export default getCityListComponent
