import React, { type SyntheticEvent } from 'react'
import { Button } from 'pcln-design-system'
import styled from 'styled-components'

const LinkButton = styled(Button)`
  text-align: left;
  font-weight: bold;
  font-size: 16px;
`

export function getClickHandler(options: {
  city: string
  state: string
  setDiscoveryCity: (city: string) => void
}) {
  const { city, state, setDiscoveryCity } = options

  setDiscoveryCity(`${city}, ${state}`)
}
export default function CityLink(props: {
  city: string
  state: string
  setDiscoveryCity: (city: string) => void
}) {
  const { city, state, setDiscoveryCity } = props
  return (
    <LinkButton
      variation="link"
      color="primary.base"
      onClick={(e: SyntheticEvent) => {
        e.preventDefault()
        void getClickHandler({
          city,
          state,
          setDiscoveryCity
        })
      }}
    >
      {city}, {state}
    </LinkButton>
  )
}
