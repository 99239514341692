import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'
import useSeti from '@/hooks/useSeti'
import useBootstrapData from '@/hooks/useBootstrapData'
import analytics from '@/shared-utils/analytics'

const HotelsDesktop = dynamic(() => import('./HotelsDesktop'))
const HotelsMobile = dynamic(() => import('./HotelsMobile'))

export default function Hotel() {
  useSeti('AA_HOTELS_LP') // AA Test: Unconditional firing and no visual changes
  const { isMobile } = useBootstrapData()
  useEffect(() => {
    analytics.fireGA4Event({
      event: 'page_view',
      attributes: {
        page_name: 'homepage',
        page_desc: 'hotel'
      }
    })
  }, [])
  return isMobile ? <HotelsMobile /> : <HotelsDesktop />
}
