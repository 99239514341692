import { useState, useEffect } from 'react'
import useBootstrapData from './useBootstrapData'

const useGeoPosition = () => {
  const { locale } = useBootstrapData()
  const [longitude, setLongitude] = useState(
    locale.longitude ? locale.longitude : null
  )
  const [latitude, setLatitude] = useState(
    locale.latitude ? locale.latitude : null
  )

  const onSuccess: PositionCallback = ({ coords }) => {
    setLongitude(coords.longitude)
    setLatitude(coords.latitude)
  }
  const onError = () => {
    // TODO: fail silently or log error
  }
  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      // TODO: potentially log error
      return
    }
    geo.getCurrentPosition(onSuccess, onError)
  }, [])

  return { longitude, latitude }
}

export default useGeoPosition
